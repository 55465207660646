<template>
    <div class="w-full p-4">
        <div class="flex justify-start items-center gap-6 mt-4 mb-8">
            <div @click="$router.push({ name: 'OrganisationCompanyDocuments'})" 
                class="text-darkPurple cursor-pointer font-semibold text-base flex gap-2 items-center"
            >
                <div ><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div> 
                Back
            </div>
            <div class="h-5 border border-borderGrey"></div>
            <h1 class="text-xl text-left font-extrabold">
                Document Management
            </h1>
            <breadcrumb :items="breadcrumbs" />
        </div>

        <div class="w-full h-5 flex justify-between items-center font-semibold text-sm">
            <div class="h-full flex justify-between items-center text-blueCrayola">
                <span class="h-full flex justify-start items-center text-romanSilver uppercase font-black text-xs pr-2 border-r border-solid border-romanSilver">
                    settings
                </span> 
                <span class="pl-2 h-full">Document ID#: 0099019200191</span>
            </div>
            <div class="h-full flex justify-between items-center">
                <div @click="isManageAllocation = true"
                    class="h-full cursor-pointer flex justify-between items-center gap-1 text-flame pr-4 border-r border-solid border-romanSilver"
                >
                    <icon icon-name="clarity-icon" size="xs"/>
                    <p>Manage Allocation</p>
                </div>
                <div class="h-full flex justify-between items-center gap-1 text-blueCrayola  ml-4">
                    <icon icon-name="carbon-setting" size="xs"/>
                    <p>Upgrade Storage</p>
                </div>
            </div>
        </div>

        <div class="w-full flex justify-between gap-4 mt-5">
            <TotalStorageOverview
                title="Total Storage " 
                :totalGB="allocatedSpace"
                :percentage="totalStorageUseage"
                :gbUseage="storageUseage"
            />
            <TotalStorageOverview
                title="Organisation Documents " 
                iconName="building-fill" 
                :totalGB="empDocTotalS"
                :gbUseage="orgDocUsage"
            />
            <TotalStorageOverview
                title="Employee Documents " 
                iconName="hero-icon" 
                :totalGB="orgDocTotalS"
                :gbUseage="empDocUseage"
            />
        </div>

        <div class="line"></div>

        <div class="w-full mt-8 flex flex-col justify-center items-center" v-if="isLoadingDocs">
            <loader size="xxs" :loader-image="false" />
        </div>

        <div v-if="!isLoadingDocs" class="w-full h-auto flex flex-col justify-between gap-4 mt-5">    
            <DocsStorage 
                type="Organisation Documents"
                typeIcon="blue-building"
                :totalGB="allocatedSpace"
                :storageUseage="storageUseage"
                :percentage="orgDocTotalUsage"
                :folders="orgFolders"
            />

            <div class="line"></div>

            <DocsStorage 
                type="Employee Documents"
                typeIcon="hero-icon"
                :totalGB="allocatedSpace"
                :storageUseage="storageUseage"
                :percentage="empDocTotalUseage"
                :folders="employeesFolders"
            />
        </div>

        <!-- <div class="w-full h-16 flex justify-end items-end gap-1 my-5">
            <Button class="text-white bg-dynamicBackBtn w-32 rounded-md h-11 flex justify-center items-center">
                Save
            </Button>
            <Button
                class="text-darkPurple bg-white w-20 h-11 flex justify-center items-center"
            >
                Cancel
            </Button>
      </div> -->

    <ManageAllocation
        v-if="isManageAllocation"
        @close=" isManageAllocation = false"
        :totalStorageUseage="totalStorageUseage"
        :orgDocTotalUsage="orgDocTotalUsage"
        :empDocTotalUseage="empDocTotalUseage"
        :orgDocuments="orgFolders"
        :empDocuments="employeesFolders"
        :totalGB="allocatedSpace"
        :storageUseage="storageUseage"
    />
    </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";

export default {
    name: "StorageSettings",
    components: {
        Breadcrumb,
        TotalStorageOverview: () => import('./TotalStorageOverview'),
        DocsStorage: () => import('./DocsStorage'),
        ManageAllocation: () => import('./manageAllocation/index')
    },
    data() {
        return {  
            isManageAllocation: false,
            isLoadingDocs: false,          
            orgDocs:[],
            employeeDocs: [],

            totalStorage: 0,
            empDocTotalSize: 0,
            orgDocTotalSize: 0,
            totalStorageUseage: 0,
            empDocTotalUseage: 0,
            orgDocTotalUsage: 0,

            breadcrumbs: [
                {
                    disabled: false,
                    text: "Document Management",
                    href: "OrganisationCompanyDocuments",
                    id: "DocumentMgt",
                },
                {
                    disabled: false,
                    text: "Organization Documents",
                    href: "OrganisationDocuments",
                    id: "OrgDocument",
                },
                {
                    disabled: false,
                    text: "Storage Settings",
                    href: "StorageSettings",
                    id: "StorageSettings",
                },
            ],
        };
    },
    computed: {
        allocatedSpace() {
            return this.kbToSize(this.totalStorage);
        },
        empDocTotalS() {
            return this.kbToSize(this.empDocTotalSize);
        },
        orgDocTotalS() {
            return this.kbToSize(this.orgDocTotalSize);
        },
        storageUseage() {          
            return this.kbToSize(this.totalStorageUseage)
        },
        empDocUseage() {
            return this.kbToSize(this.empDocTotalUseage)
        },
        orgDocUsage() {
            return this.kbToSize(this.orgDocTotalUsage)
        },
        usedtotalSPercentage() {
            if (this.totalStorage === 0) {
                return 0;
            }
            return (this.totalStorageUseage / this.totalStorage) * 100;
        },
        orgFolders(){
            return this.orgDocs;
        },
        employeesFolders(){
            return this.employeeDocs
        }
    },
    methods: {
        getStorage(){
            this.isLoadingDocs = true;
            this.$_getDocStorage().then(res => {
                this.orgDocs = res.data.data.orgDocuments;
                this.employeeDocs = res.data.data.employeeDocuments;
                
                this.totalStorage = res.data.data.summary.allocatedSpace;
                this.orgDocTotalSize = res.data.data.summary.orgDocAllocatedSpace;
                this.empDocTotalSize = res.data.data.summary.empDocAllocatedSpace;

                this.empDocTotalUseage = res.data.data.summary.empDocTotalSize;
                this.orgDocTotalUsage = res.data.data.summary.orgDocTotalSize;
                this.totalStorageUseage = this.empDocTotalUseage + this.orgDocTotalUsage;
                this.isLoadingDocs = false;
            });
        },
        kbToSize(kb) {   
            const sizes = ['KB', 'MB', 'GB', 'TB', 'PB'];
            if (kb === 0) return '0 KB';
            if (kb < 1) return `${(kb * 1024).toFixed(1)} KB`; // Converted small KB to Bytes
                const i = parseInt(Math.floor(Math.log(kb) / Math.log(1024)), 10);
                const sizeIndex = Math.min(i, sizes.length - 1);
            if (sizeIndex === 0) return `${kb.toFixed(1)} ${sizes[sizeIndex]}`;
            return `${(kb / (1024 ** sizeIndex)).toFixed(1)} ${sizes[sizeIndex]}`;
        }
    },
    mounted() {
        this.getStorage()
    },
};
</script>

<style scoped>
.line{
  border: 1px solid #C2C7D6;
  width: 100%;
  height: 0;
  margin: 30px auto;
  border-radius: 10px;
  padding: 0;
}
</style>
